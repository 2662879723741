import { createCarTypesParamsSerializer, createInjectAPICache } from '../car-types/api';
import { createHttpClient } from './axiosDefaults';
function createBeaconHttpClient(baseUrl, fallbackHttpClient) {
  return {
    post(url, data) {
      if (typeof window !== 'undefined' && typeof navigator.sendBeacon === 'function') {
        navigator.sendBeacon("" + baseUrl + url, new Blob([JSON.stringify(data)], {
          type: 'application/json'
        }));
      } else {
        fallbackHttpClient.post(url, data);
      }
    }
  };
}
export function createHttpClients(configuration, logger) {
  const {
    baseApiUrl,
    baseNapiUrl
  } = configuration;
  const apiHttpClient = createHttpClient({
    baseURL: baseApiUrl
  }, configuration, logger);
  const napiHttpClient = createHttpClient({
    baseURL: baseNapiUrl
  }, configuration, logger);
  const carTypesHttpClient = createInjectAPICache(createHttpClient({
    baseURL: "" + baseApiUrl,
    paramsSerializer: createCarTypesParamsSerializer(configuration.fullLocale)
  }, configuration, logger));
  const baseNoV1Url = baseApiUrl.replace('/v1', '');
  const noV1ApiHttpClient = createHttpClient({
    baseURL: baseNoV1Url
  }, configuration, logger);
  const noV1BeaconHttpClient = createBeaconHttpClient(baseNoV1Url, noV1ApiHttpClient);
  const sameOriginHttpClient = createHttpClient({
    baseURL: ''
  }, configuration, logger);
  return {
    apiHttpClient,
    napiHttpClient,
    carTypesHttpClient,
    noV1ApiHttpClient,
    noV1BeaconHttpClient,
    sameOriginHttpClient
  };
}