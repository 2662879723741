import { GDPRTrackingService } from '@wkda/gdpr-tracking-service';
import { MarketingTrackingService } from '@wkda/marketing-tracking-service';
export function create(_ref, _ref2) {
  let {
    httpClient,
    config
  } = _ref;
  let {
    gdprBannerModule,
    waKey,
    logger,
    beaconHttpClient
  } = _ref2;
  function createAPIService() {
    return {
      post: (url, data) => httpClient.post(url, data, {
        params: {
          wa_key: waKey
        }
      })
    };
  }
  function trackBannerLanding(req, res, loggerService) {
    new GDPRTrackingService({
      locale: config.locale,
      apiService: createAPIService(),
      loggerService,
      userHash: req.cookies.PHPSESSID,
      isBannerContentAvailable: gdprBannerModule.isBannerAvailable
    }).trackBannerLanding(req, res);
  }
  const marketingTrackingService = new MarketingTrackingService(
  // @ts-expect-error - service expects AxiosInstance, though it should be more generic
  beaconHttpClient, logger);
  return {
    trackBannerLanding,
    marketingTrackingService
  };
}