import merge from 'lodash-es/merge';
import { getBaseApiUrl, getBaseApiUrlFromLocale, getBasePapiUrlByLocale, getCustomerBaseApiUrl, getGoogleClientId, getLoggerBaseApiurl, getPartnerBaseApiUrl, getPartnerBaseNapiUrl, isShortUrl, shortenUrl } from '../apiUrls';
import { DOIDomains } from '../doiDomainConfig';
import { timezoneMap } from '../timezones';
import invariant from 'tiny-invariant';
const defaults = {
  locale: 'de',
  features: {
    hasCustomerAccount: {
      allowLocales: ['de', 'es', 'fr', 'it', 'de-AT', 'fr-BE', 'nl-BE', 'sv-SE', 'nl', 'pt']
    },
    emailSubscription: {
      allowLocales: ['de']
    },
    thankYouPhone: {
      allowLocales: ['de', 'es', 'it', 'fr', 'de-AT', 'nl-BE', 'fr-BE', 'sv-SE', 'pl', 'nl', 'pt', 'da']
    }
  },
  currency: 'EUR',
  locales: {
    'de': 'de',
    'es': 'es',
    'nl': 'nl',
    'it': 'it',
    'fr': 'fr',
    'pl': 'pl',
    'pt': 'pt',
    'da': 'da',
    'de-AT': 'de-AT',
    'nl-BE': 'nl-BE',
    'fr-BE': 'fr-BE',
    'sv-SE': 'sv-SE'
  },
  fullLocalesMap: {
    'de': 'de-DE',
    'es': 'es-ES',
    'nl': 'nl-NL',
    'it': 'it-IT',
    'fr': 'fr-FR',
    'pl': 'pl',
    'pt': 'pt',
    'da': 'da',
    'de-AT': 'de-AT',
    'nl-BE': 'nl-BE',
    'fr-BE': 'fr-BE',
    'sv-SE': 'sv-SE'
  },
  countriesMap: {
    'de': 'de',
    'es': 'es',
    'nl': 'nl',
    'it': 'it',
    'fr': 'fr',
    'pl': 'pl',
    'pt': 'pt',
    'da': 'dk',
    'de-AT': 'at',
    'nl-BE': 'be',
    'fr-BE': 'be',
    'sv-SE': 'se'
  },
  languagesMap: {
    'de': 'de',
    'nl': 'nl',
    'it': 'it',
    'fr': 'fr',
    'es': 'es',
    'pl': 'pl',
    'de-AT': 'de',
    'nl-BE': 'nl',
    'fr-BE': 'fr',
    'sv-SE': 'sv',
    'da': 'da',
    'pt': 'pt'
  },
  GTMIdByLocales: {
    'de': 'GTM-MPZV3T',
    'es': 'GTM-TWQMWC',
    'nl': 'GTM-TWQMWC',
    'it': 'GTM-TWQMWC',
    'fr': 'GTM-TWQMWC',
    'pl': 'GTM-PKWS6B',
    'pt': 'GTM-MKGLMRX',
    'da': 'GTM-TWQMWC',
    'de-AT': 'GTM-PKHLBS',
    'nl-BE': 'GTM-TWQMWC',
    'fr-BE': 'GTM-5W3T5W2',
    'sv-SE': 'GTM-TWQMWC'
  },
  timezoneMapper: timezoneMap,
  googleAPIKey: 'AIzaSyBxTU5kgHxDXdnDEp1-rWdDJs2GqAoYjdk',
  googleClientId: '535827184523-16s72ovt5uk68vet6al2u47d18seuel4.apps.googleusercontent.com',
  doiDomains: DOIDomains,
  cookieBannerName: '@wkda/partials/cookie-banner/',
  /**
   * Query params that are allowed to be passed
   * to another page on redirect
   */
  whitelistedQueryParams: ['wa_key', 'partner_domain', 'locale']
};
export default function generateBaseConfig() {
  let overrides = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  const env = typeof window === 'undefined' ? process.env.ENV : window['__ENV'];
  const enableRumPercentage = typeof window === 'undefined' ? process.env.ENABLE_RUM_PERCENTAGE :
  // @ts-ignore
  window['__ENABLE_RUM_PERCENTAGE'];
  const appName = process.env.APP_NAME;
  const appVersion = process.env.APP_VERSION;
  const serverVersion = process.env.SERVER_VERSION;
  const serverName = process.env.SERVER_NAME;
  const assetsPublicPath = typeof window === 'undefined' ? process.env.ASSETS_PUBLIC_PATH : window['__ASSETS_PUBLIC_PATH'];
  const funnelBaseUrl = typeof window === 'undefined' ? process.env.FUNNEL_BASE_URL : window['__FUNNEL_BASE_URL'];
  invariant(funnelBaseUrl, 'FUNNEL_BASE_URL is not defined!');
  invariant(validateEnv(env), "Environment value '" + env + "' is incorrect");
  const options = merge({}, {
    ...defaults,
    assetsPublicPath,
    // Remove leading or trailing slashes
    funnelBaseUrl: funnelBaseUrl.replace(/^\/|\/$/g, '')
  }, overrides);
  const {
    locale,
    isPartnerFunnel
  } = options;
  let baseApiUrl = getBaseApiUrl(env);
  let proxyBaseApiUrl = getBaseApiUrl('qa').replace('v1', '');
  let baseNapiUrl = getBaseApiUrlFromLocale(locale, env);
  let proxyBaseNapiUrl = getBaseApiUrlFromLocale(locale, 'qa').replace('napi', '');
  let basePapiUrl = getBasePapiUrlByLocale(locale, env);
  let proxyBasePapiUrl = getBasePapiUrlByLocale(locale, 'qa');
  let googleClientId = getGoogleClientId(env);
  if (typeof window !== 'undefined' && isShortUrl(window.location.host)) {
    baseNapiUrl = shortenUrl(baseNapiUrl);
  }
  if (isPartnerFunnel) {
    baseApiUrl = getPartnerBaseApiUrl(env);
    proxyBaseApiUrl = getPartnerBaseApiUrl('qa').replace('v1', '');
    baseNapiUrl = getPartnerBaseNapiUrl(env);
    proxyBaseNapiUrl = getPartnerBaseNapiUrl('qa').replace('napi', '');
  }
  const rumServerUrl = {
    // For testing
    local: 'https://rum.qa.services.auto1.team',
    preprod: '',
    qa: 'https://rum.qa.services.auto1.team',
    prod: 'https://rum.prod.services.auto1.team'
  }[env];
  return {
    ...options,
    // app name and version are useful for debugging and used when sending logs.
    appName,
    appVersion,
    enableRumPercentage,
    serverVersion,
    serverName,
    rumServerUrl,
    env,
    loggerBaseApiUrl: getLoggerBaseApiurl(env),
    customerBaseApiUrl: getCustomerBaseApiUrl(locale, env),
    baseApiUrl,
    proxyBaseApiUrl,
    baseNapiUrl,
    proxyBaseNapiUrl,
    basePapiUrl,
    proxyBasePapiUrl,
    googleClientId,
    fullLocale: options.fullLocalesMap[locale],
    country: options.countriesMap[locale],
    language: options.languagesMap[locale]
  };
}
function validateEnv(env) {
  const availableEnvs = ['local', 'qa', 'preprod', 'prod'];
  return env !== undefined && availableEnvs.includes(env);
}